.faq-container {
  .faq-heading {
    font-weight: 600;
    font-size: 5.2rem;
    line-height: 61px;
    color: #000000;
    margin: 0;
  }

  .faq-panel {
    border-radius: 20px;
    margin-top: 2.4rem;

    .sub-content-heading {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 16px;
      color: #6E6AF0;
    }

    .rs-panel-heading {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 19px;
      color: #6E6AF0;
    }

    &.rs-panel-collapse {
      margin-top: 0;

      .rs-panel-body {
        padding: 0;
        padding-left: 2rem;
        padding-right: 5rem;
        padding-bottom: 2.4rem;
      }
    }

    p {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 24px;
      color: #5350B4;
    }
  }
}