.password-reset-container {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.4rem 1.8rem;

  .password-reset-form {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
    box-shadow: -10px 7px 40px rgba(100, 100, 111, 0.1);
    border-radius: 0.8rem;

    .password-reset-form__header {
      margin: 0;
      color: #424090;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2rem;
      margin-bottom: 2.4rem;
    }

    .reset-password-input {
      width: 100%;
      margin-bottom: 2.4rem;
    }
  }
}
