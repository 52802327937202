.add-challenge-form {
  margin-top: 2.4rem;

  .challenge-icon {
    width: 19rem;
    height: 19rem;
  }

  .challenge-form-fields-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
    margin-top: 2.4rem;

    @media (max-width: 839px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .tournament-grid-form-100 {
      grid-column: 1 / span 2;

      @media (max-width: 839px) {
        grid-column: 1;
      }
    }

    .prize-tooltip {
      display: flex;
      align-items: center;

      .prize-head {
        margin-right: 1rem;
      }
    }

    .prize-config-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2.4rem;

      @media (max-width: 839px) {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 2.4rem;
      }

      .challenge-form-control-50 {
        display: flex;
        align-items: flex-start;
        width: 100%;

        .rs-form-group {
          width: 100%;
        }

        p {
          display: none;
        }

        .delete-parameter-btn {
          margin-left: 2.4rem;
          margin-top: 0.5rem;
        }

        .bar-separator {
          display: none;
          height: 1px;
          width: 100%;
          background-color: grey;
        }

        @media (max-width: 839px) {
          flex-direction: column;

          p {
            display: inline-block;
            margin-left: 0.5rem;
          }

          .delete-parameter-btn {
            margin-left: 0;
            margin-bottom: 2.4rem;
            width: 100%;
          }

          .bar-separator {
            display: block;
          }
        }
      }
    }
  }
}

@media (max-width: 839px) {
  .add-prize-btn {
    width: 100%;
    margin-top: 1rem;
  }
}
