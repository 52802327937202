.uploader-container {
  width: 100%;
  height: 100%;

  .uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s linear;
    border: 1px dashed rgba(98, 103, 107, 0.4);
    border-radius: 6px;

    &.round {
      border-radius: 100%;
      position: relative;
    }

    &.error {
      border-color: red;
    }

    &:hover {
      background-color: rgba(98, 103, 107, 0.1);
    }

    p {
      margin-top: 1rem;
      color: #b7b5c6;
      font-size: 1rem;
      line-height: 1.2rem;
      text-align: center;
    }
  }

  .uploader-preview {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 1rem;

    &.round {
      border-radius: 50%;

      img {
        border-radius: 50%;
      }

      .label-overlay {
        border-radius: 50%;
      }
    }

    .label-overlay {
      position: absolute;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      top: 1rem;
      left: 1rem;
      padding: 1rem;
      border-radius: 6px;
      transition: all 0.2s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 1.8rem;
      background-color: rgba(#000000, 0.4);
      @include font-semi-bold;
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }

    .close-icon {
      position: absolute;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0rem;
      top: 0;
      cursor: pointer;

      h6 {
        color: #ffffff;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
}
