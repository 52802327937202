@mixin commonStyle {
  width: 100%;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 16px;
  color: #B7B5C6;
}

.request-sponsor {
  .rs-modal-body {
    padding-bottom: 0;
  }

  .attachment {
    height: 9.2rem;
  }

  .select {
    .rs-picker-toggle {
      margin-top: 2.4rem;
      @include commonStyle;
      border: 1px solid rgba(98, 103, 107, 0.4);
      border-radius: 4px;

      .rs-picker-toggle-value {
        @include commonStyle;
        color: #B7B5C6;
      }
    }
  }

  .message-input {
    border: 1px solid rgba(98, 103, 107, 0.4);
    border-radius: 4px;
    resize: none;
    height: 19rem;
    padding: 1.2rem 1.6rem;
    @include commonStyle;
    margin-top: 2.4rem;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }
}