.game-banner-wrapper {
  height: 18rem;
  width: 100%;
  display: block;
  margin-bottom: 1.6rem;

  .game-banner {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;
  }
}

.game-description {
  width: 100%;

  h5 {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 1.2rem;
    font-weight: 500;
  }
}