.space-detail-name-image {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  overflow: hidden;
  width: auto;
  max-height: 18.2rem;

  .space-detail-name-description {
    padding: 2rem 1.6rem 1.6rem 2.4rem;

    .space-detail-name {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 19px;
      margin-bottom: 0.4rem;
      text-overflow: ellipsis;
      max-width: 50rem;
      white-space: nowrap;
      overflow: hidden;

      @media (min-width: 1200px) {
        max-width: 20rem;
      }

      @media (min-width: 1300px) {
        max-width: 25rem;
      }

      @media (min-width: 1400px) {
        max-width: 30rem;
      }

      @media (min-width: 1500px) {
        max-width: 36rem;
      }
    }

    .space-detail-member {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 14px;
    }

    .space-detail-mem-text {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 14px;

      margin-left: 0.3rem;
    }

    .social-links-container {
      display: flex;
      justify-content: flex-start;
      max-width: 10rem;
      margin-bottom: 1.5rem;
      margin-top: 1.2rem;

      .social-links {
        margin-right: 1.3rem;
      }
    }

    .space-description {
      text-align: left;
      font-weight: 300;
      font-size: 1.5rem;
      line-height: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      word-break: break-word;

      @media (min-width: 1200px) {
        width: 20rem;
      }

      @media (min-width: 1300px) {
        width: 25rem;
      }

      @media (min-width: 1400px) {
        width: 30rem;
      }

      @media (min-width: 1500px) {
        width: 36rem;
      }
    }
  }

  .space-details-image-wrapper {
    overflow: hidden;
    flex: 0 0 auto;

    .space-details-image {
      height: 18.2rem;
      width: 22.2rem;
    }
  }
}