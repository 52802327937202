@import '~rsuite/styles/index.less';

@primary-color: #6e6af0;
@reset-import: false;

.rs-theme-dark {
  --rs-btn-primary-bg: #3c3f43;
  --rs-btn-primary-hover-bg: @primary-color ;
  --rs-panel-shadow: rgba(149, 157, 165, 0.12);
  --rs-input-focus-border: @primary-color;
  --rs-text-heading: #ffffff;
  --rs-btn-ghost-border: @primary-color;
  --rs-btn-ghost-active-border: @primary-color;
  --rs-btn-ghost-text: @primary-color;
  --rs-btn-ghost-active-text: @primary-color;
  --rs-btn-ghost-hover-border: @primary-color;
  --rs-btn-ghost-hover-text: @primary-color;
  --rs-state-focus-shadow: none;
  --rs-picker-value: none;
  --rs-toggle-checked-bg: @primary-color;
  --rs-toggle-checked-hover-bg: @primary-color;
  --rs-radio-checked-bg: @primary-color;
  --rs-checkbox-checked-bg: @primary-color;
  --rs-picker-count-bg: @primary-color;
  --rs-btn-primary-active-bg: @primary-color;
}

.rs-theme-light {
  --rs-text-primary: #1B1952;
  --rs-state-focus-shadow: none;
}

h4 {
  font-size: 2.4rem;
  font-weight: 600;
  --rs-toggle-hover-bg: @primary-color;
}
@H700: #6E6AF0;@B600: #B7B5C6;@B800: #6E6AF0;@input-color: #1b1952;@dropdown-link-color: #B7B5C6;@base-color: #6E6AF0;@modal-title-color: #1B1952;@modal-title-font-size: 20px;@modal-close-btn-color: #62676B;@--rs-btn-primary-bg: #6E6AF0;