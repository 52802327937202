.wallet-card {
  border: 1px solid #e1e1e1;
  border-radius: 2rem;
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    padding: 1.8rem;
    background-color: #e1e0fb;

    svg.icon {
      fill: #e1e0fb;

      .fill-path {
        fill: #6E6AF0;
      }
    }
  }

  &:hover {
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
    border-color: #6e6af0;
    transition: ease-in-out .3s;

    .content {
      background-color: #6e6af0;
      transition: ease-in-out .3s;

      svg.icon {
        .fill-path {
          fill: #ffffff;
          transition: ease-in-out .3s;
        }

        .stroke-fill {
          stroke: #ffffff;
          fill: #6e6af0;
          transition: ease-in-out .3s;
        }

        .dollar-storke-fill {
          fill: #ffffff;
          transition: fill ease-in-out .3s;
        }
      }
    }
  }
}

.text-container {
  display: flex;
  justify-content: center;

  .text {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1.2rem;
    text-align: center;
  }
}