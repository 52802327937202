@mixin tag {
  font-weight: 400;
  font-size: 1.4rem;
  border-radius: 4rem;
  line-height: 13px;
  padding: 8px 20px;
  text-transform: capitalize;

  &.last-child {
    margin-left: 0.5rem;
  }
}

.space-poll-detail-container {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  @media (min-width: 1200px) {
    gap: 0;
    flex-direction: row;
    min-height: calc(100vh - 12rem);
  }

  .left-sec {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    @media (min-width: 1200px) and (max-width: 1399px) {
      padding-right: 2.4rem;
      width: 52.4rem;
    }

    @media (min-width: 1400px) {
      padding-right: 2.4rem;
      width: 70.6rem;
    }

    .space-info-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 1.6rem;

      .icon-title-container {
        display: flex;
        align-items: center;

        .post-icon {
          width: 4.8rem;
          height: 4.8rem;

          .rs-avatar-image {
            width: 4.8rem;
            height: 4.8rem;
          }
        }

        .space-title {
          display: flex;
          flex-direction: column;
          margin-left: 1.2rem;

          .posted-by {
            font-size: 2rem;
            font-weight: 600;
          }

          .time-elapsed {
            font-size: 1rem;
          }
        }
      }

      .tag-container {
        margin-left: auto;

        .tag-active {
          @include tag;
          background-color: #007E51;
        }

        .tag-inactive {
          @include tag;
          background-color: #E33342;
        }
      }

      .option-btn {
        background-color: transparent;
      }
    }

    .like-comment-count {
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
      position: relative;

      .reaction-wrapper {
        position: absolute;
        left: 2rem;
        bottom: 2.5rem;
      }

      .like-comment-status {
        display: flex;
        align-items: center;

        &:last-child {
          margin-left: 1.6rem;
        }

        .count {
          margin-left: 0.4rem;
        }
      }
    }
  }

  .right-sec {
    width: 100%;
    margin-bottom: 4rem;

    .poll-question {
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: 0.36px;
    }

    .vote-detail-container {
      display: grid;
      gap: 1.6rem;
      margin-top: 2.4rem;
    }

    .vote-wrapper {
      padding: 1rem;
      box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
      border-radius: 6px;

      .options {
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0.32px;
      }

      .hr {
        width: 100%;
        height: 1px;
        background: #D2D1FA;
        margin: 1.2rem 0;
      }

      .value-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        .vote {
          font-size: 1.6rem;
          font-weight: 500;
          letter-spacing: 0.32px;
          opacity: 0.8;
        }
      }
    }
  }

  .post-comment-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: calc(100vh - 70rem);

    @media (max-width: 700px) {
      height: calc(100vh - 52rem);
    }

    @media (min-width: 1200px) {
      height: calc(100vh - 12rem);
      border-left: 1px solid #e1e1e1;
    }

    .comment-section {
      overflow-y: auto;
      margin-top: 2.4rem;

      @media (min-width: 1200px) {
        padding-left: 2.4rem;
        margin-top: 0;
      }

      .no-comments {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: calc(100vh - 50rem);
        justify-content: center;

        @media (max-width: 1200px) {
          min-height: calc(100vh - 70rem);
        }

        .no-comment-text {
          font-size: 1.4rem;
          margin-top: 1.6rem;
          @include font-semi-bold;
        }
      }
    }

    .add-comment-container {
      width: 100%;
      position: absolute;
      bottom: -2.4rem;
      left: -24px;
      width: calc(100% + 4.8rem);

      @media (min-width: 1200px) {
        position: relative;
        bottom: auto;
        width: calc(100% + 2.4rem);
        left: auto;
      }

      .comment-textarea {
        width: 100%;
        border: none;
        border-radius: 0;
        padding: 2.1rem 4.5rem 2.2rem 2.4rem;
        box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
      }

      .send-btn {
        position: absolute;
        bottom: 2rem;
        right: 2.4rem;
        cursor: pointer;
      }
    }
  }
}