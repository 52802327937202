.creators-card {
  border-radius: 1.2rem;
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
  padding: 1.2rem;
  cursor: pointer;
  height: 40.5rem;
  width: 100%;
  transition: all 0.2s ease-out;

  &:hover {
    scale: 1.03;
  }

  .creators-image {
    width: 100%;
    height: 14.2rem;
    object-fit: fill;
    border-radius: 6px;
  }

  .name-container {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    margin-top: 1.2rem;

    .name {
      font-size: 1.8rem;
      font-weight: 600;
      max-width: 20rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: capitalize;
    }
  }

  .residence {
    font-size: 1.2rem;
    font-weight: 500;
    opacity: 0.6;
    border-bottom: 1px #1B1952 solid;
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
  }

  .price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.2rem 0;

    .name {
      font-size: 1.2rem;
      opacity: 0.6;
      font-weight: 500;
    }

    .price {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  .social-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .links-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.6rem;
        padding: 0.6rem;
        border: 1px solid rgba(27, 25, 82, 0.10);
      }
    }

    .type {
      padding: 0.7rem 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: capitalize;
      border-radius: 0.6rem;
      background: rgba(110, 106, 240, 0.10);
    }

    &.no-links {
      justify-content: flex-end;
    }
  }
}

.no-effect {
  height: auto;

  &:hover {
    scale: 1;
  }
}