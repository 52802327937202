.space-img-uploader-container {
  margin: 2.4rem 0;

  .space-img-uploader {
    height: 30rem;
  }
}

.post-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
}