.shorts-card-wrapper {
  width: 100%;
  margin-bottom: 1.2rem;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #e5e5ea;

  &.clickable {
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      border-color: #6e6af0;
      transform: scale(1.05);
    }
  }

  .shorts-preview-wrapper {
    width: 100;
    height: 18rem;
    position: relative;

    .shorts-card-header {
      padding: 0.8rem 1rem;
      width: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);

      .show-shorts-status-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .show-shorts-status {
          font-size: 1.4rem;
          color: #007e51;
          font-weight: 600;

          &.Inactive {
            color: #e33342;
          }

          &.Published {
            color: #007e51;
          }

          &.Pending {
            color: #ffd23f;
          }
        }

        .icon-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          border-radius: 0.4rem;
          background-color: rgba(110, 106, 240, 0.6);
        }
      }

      .rs-btn-toggle.shorts-status {
        &.Inactive {
          background-color: #e33342;
        }

        &.Published {
          background-color: #007e51;
        }

        &.Pending {
          background-color: #ffd23f;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .shorts-details-wrapper {
    width: 100%;

    .shorts-detail {
      font-weight: 600;
    }

    .shorts-content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: auto;
    }
  }
}
