.reply-card-wrapper {
  display: flex;
  align-items: flex-start;
  border-radius: 1.6rem;
  overflow: hidden;
  padding: 1.2rem 1.2rem 1rem 1.2rem;
  margin-bottom: 0.8rem;
  border: 1px solid #3c3f43;


  .reply-card-icon {
    width: 2.4rem;
    height: 2.4rem;
    flex: 0 0 auto;
    background-color: #ffffff;

    .rs-avatar-image {
      width: 2.4rem;
      height: 2.4rem;
      line-height: normal;
    }
  }

  .reply-card-comment {
    margin-left: 1.2rem;

    .reply-posted-by {
      font-size: 1.6rem;
      @include font-semi-bold;
    }

    .link {
      color: rgb(110, 106, 240);
    }

    .reply-posted {
      font-size: 1.4rem;
      word-break: break-word;
    }

    .reply-options-container {
      display: flex;
      align-items: center;

      .reply-options {
        font-size: 1rem;
        font-weight: 500;

        &.delete-option {
          cursor: pointer;
        }
      }

      .dot {
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        overflow: hidden;
        background-color: #3c3f43;
        margin: 0 0.5rem;
      }

      .show-reply {
        font-size: 1rem;
        text-decoration: underline;
      }
    }
  }
}