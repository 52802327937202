.sign-up-container {
  width: 100%;
  max-width: 68.3rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 72rem;
  height: 100%;
  overflow: hidden;
  padding: 2.4rem;

  @media (max-height: 660px) {
    align-items: normal;
  }

  .sign-up-form-wrapper {
    overflow-y: auto;
    width: 100%;
    max-width: 36rem;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .logo-container {
      max-width: 18rem;
      margin: 0 auto;
      margin-bottom: 5rem;

      img {
        width: 100%;
      }
    }

    .sign-up-control {
      width: 100%;
      margin-bottom: 1.6rem;
    }

    .sign-up-btn {
      width: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      padding: 1rem;
      border-radius: 6px;
      background-color: #424090;
      margin-top: 1.6rem;
    }
  }
  .sign-up-type-separator {
    height: 1px;
    background-color: #e5e5e5;
    margin: 2.4rem 0;
    position: relative;

    &::after {
      content: "Or";
      color: #000000;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      padding: 0 1.8rem;
    }
  }

  .google-sign-up {
    width: 100%;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #007aff;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
    border-radius: 6px;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
    }
  }
  .moderator-sign-up {
    width: 100%;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #007aff;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
    border-radius: 6px;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:1rem ;

    a {
      text-decoration-line: none;
    }
  }

  .switch-login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.4rem;

    p {
      margin-right: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #1a1a1a;
    }

    button {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #007aff;
      background-color: transparent;
      padding: 0;
    }
  }
}
