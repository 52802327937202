.tournament-details-container {
  width: 100%;

  .tourney-banner-panel {
    .rs-panel-body {
      padding: 0;
    }

    .tournament-banner {
      width: 100%;
      height: 32rem;
      object-fit: cover;
    }
  }

  .tournament-hosted-section {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .tourney-title-panel {
      margin-top: 1.6rem;
      box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
      border-radius: 1.2rem;
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      word-break: break-all;
      width: 100%;

      .tourney-title {
        font-size: 2rem;
        @include font-semi-bold;
      }

      .tourney-description {
        font-size: 1.6rem;
        margin-top: 1.6rem;
        font-weight: 300;
        line-height: 18.77px;
      }
    }

    .tourney-hosted-by {
      display: flex;
      flex-direction: column;
      background-color: rgba(110, 106, 240, 0.2);
      border: 1px solid rgba(110, 106, 240, 0.8);
      flex: 0 0 auto;
      padding: 1.6rem 2.4rem 2.4rem 2.4rem;
      margin-top: 1.6rem;
      box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
      border-radius: 1.2rem;
      width: 45.6rem;
      margin-left: 2.4rem;

      .header-tag {
        font-size: 1.6rem;
        @include font-bold;
      }

      .space-detail-container {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;

        .space-icon {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          overflow: hidden;
        }

        .space-name {
          font-size: 1.6rem;
          margin-left: 1rem;
          @include font-bold;
        }
      }
    }
  }

  .tourney-details-panel-container {
    display: flex;
    align-items: flex-start;
    margin-top: 2.4rem;

    @media (max-width: 1199px) {
      flex-direction: column;
    }

    .tourney-details {
      width: 45.6rem;
      padding: 2.4rem;
      border-radius: 1.2rem;
      flex: 0 0 auto;
      margin-right: 2.4rem;
      box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);

      @media (max-width: 1199px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2.4rem;
      }

      .detail-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 2rem;
          @include font-semi-bold;
        }

        .tournament-status {
          font-size: 1.6rem;
          text-transform: capitalize;
          color: #007e51;
        }
      }

      .detail-content {
        display: flex;
        flex-direction: column;

        .detail-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2rem;

          @media (max-width: 575px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          .detail-text {
            font-size: 1.6rem;
            font-weight: 300;
          }
        }
      }
    }

    .tourney-rounds-container {
      width: 100%;
      position: relative;

      .wildcard-invite {
        font-size: 2rem;
        font-weight: 600;
      }

      .tournament-wildcard-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.4rem;
        box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
        border-radius: 1.2rem;
        margin-bottom: 2rem;

        @media (max-width: 935px) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media (min-width: 1201px) and (max-width: 1420px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .wildcard-invite-link {
          font-size: 1.6rem;
          font-weight: 300;
        }
      }

      .tourney-round {
        .round-card {
          border-radius: 1.2rem;
          box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
          padding: 2.4rem;
          margin-bottom: 2rem;

          .tourney-round-title {
            display: flex;
            justify-content: space-between;

            @media (max-width: 500px) {
              flex-direction: column;
            }

            span {
              font-size: 2rem;
              @include font-semi-bold;
            }
          }

          .tourney-round-type {
            display: flex;
            justify-content: space-between;
            margin-top: 1.6rem;
            align-items: center;

            @media (max-width: 500px) {
              flex-direction: column;
            }

            span {
              font-size: 1.6rem;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .tournament-details {
    h6 {
      margin-bottom: 2rem;
    }

    .rs-col {
      margin-bottom: 1rem;
      height: 2.5rem;

      &:nth-child(odd) {
        @include font-semi-bold;
      }
    }
  }

  .round-details {
    h6 {
      margin: 2rem 0;
    }
  }

  .game-details {
    h6 {
      margin: 2rem 0;
    }

    .rs-col {
      margin-bottom: 1rem;
      min-height: 2.5rem;

      &:nth-child(odd) {
        @include font-semi-bold;
      }
    }
  }
}