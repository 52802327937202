@mixin font-light {
  font-weight: 300;
}

@mixin font-regular {
  font-weight: 400;
}

@mixin font-semi-bold {
  font-weight: 600;
}

@mixin font-bold {
  font-weight: 700;
}

@mixin header-reset {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 635px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    margin: 0;
  }

  .header-cta {
    align-self: center;

    @media (max-width: 635px) {
      align-self: flex-end;
    }
  }
}
