.header-container {
  margin-top: 1.6rem;
  overflow: visible;
  border-radius: 1.2rem;
  border: none;

  .collapsing {
    overflow: hidden;
  }

  .rs-panel-heading {
    padding: 1.2rem 2.4rem .8rem 2.4rem;

    &::before {
      content: none;
    }
  }

  &.rs-panel {
    overflow: visible;
  }

  .rs-panel-collapse {
    &.header-container {
      margin-top: 0;

      .rs-panel-body {
        padding: 0;
        padding-left: 2rem;
        padding-bottom: 2.4rem;
      }
    }
  }

  .panel-content {
    padding-left: 21rem;
    display: flex;
    flex-direction: column;

    .add-mobile {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 16px;
    }
  }
}