.shorts-owner-details {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1.6rem 0 1.6rem 0;
  border-top: 1px solid #e1e1e1;

  @media (min-width: 1200px) {
    padding: 1.6rem 2.4rem 1.6rem 2.4rem;
    border-bottom: 1px solid #e1e1e1;
    border-top: none;
  }

  .shorts-owner-icon {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    position: relative;
    overflow: auto;

    img {
      object-fit: cover;
    }
  }

  .space-name-container {
    display: flex;
    flex-direction: column;
    margin-left: 1.6rem;

    .space-name {
      font-size: 1.2rem;
      font-weight: 600;

      @media (min-width: 1200px) {
        font-size: 1.8rem;
      }
    }

    .time-elapsed {
      font-size: 1.2rem;
    }
  }
}

.comment-container {
  padding-bottom: 5rem;

  @media (min-width: 1200px) {
    height: calc(100vh - 28rem);
    overflow-y: auto;
    padding-left: 2.4rem;
    padding-bottom: 8rem;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .no-comments {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 50rem);
    justify-content: center;

    @media (max-width: 1200px) {
      min-height: calc(100vh - 70rem);
    }

    @media (max-width: 1200px) {
      min-height: calc(100vh - 70rem);
    }

    .no-comment-text {
      font-size: 1.4rem;
      margin-top: 1.6rem;
      @include font-semi-bold;
    }
  }

  .view-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.add-comment-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 5.6rem;
  width: calc(100% - 5.6rem);

  @media (min-width: 1200px) {
    position: absolute;
    width: calc(100% + 2.4rem);
    left: auto;
  }

  .comment-textarea {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 2.1rem 4.5rem 2.2rem 2.4rem;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  }

  .send-btn {
    position: absolute;
    bottom: 1.8rem;
    right: 1rem;
    cursor: pointer;

    @media (min-width: 1200px) {
      position: absolute;
      bottom: 2rem;
      right: 2.4rem;
      cursor: pointer;
    }
  }
}