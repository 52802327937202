.row-gap {
  margin-bottom: 2.4rem;

  .create-product-uploader {
    height: 19rem;
  }
}

.error {
  color: red;
  font-size: 1.2rem;
}

.create-product-button {
  display: flex;
  justify-content: flex-end;

  .create-button {
    padding: 1rem 2rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 19px;
  }
}