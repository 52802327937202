.xo-product-card {
  margin-bottom: 1.6rem;
  border: 1px solid transparent;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);

  &:hover {
    border: 1px solid #6e6af0;
  }

  .rs-panel-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2rem;
  }

  .xo-product-title-container {
    display: flex;
    align-items: center;

    .xo-product-image {
      width: 5rem;
      height: 5rem;
      border-radius: 100%;
      overflow: hidden;
      position: relative;
      margin-right: 1.6rem;

      .icon {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .xo-product-title {
      display: flex;
      flex-direction: column;

      .xo-product-name {
        font-size: 1.4rem;
        font-weight: 500;
      }

      .xo-product-amount {
        display: flex;
        align-items: center;

        .amount {
          font-size: 1.6rem;
          margin-right: 0.4rem;
          font-weight: 600;
        }
      }
    }
  }

  .xo-product-action-container {
    display: flex;
    flex-wrap: nowrap;

    .product-icon-btn {

      .delete-icon,
      .edit-icon {
        .path-fill {
          fill: #b7b5c6;
        }
      }

      .delete-icon {
        &:hover {
          fill: #e93e53;
        }
      }

      .edit-icon {
        &:hover {
          fill: #6e6af0;
        }
      }
    }
  }
}