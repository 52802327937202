.title {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.field-container {
  margin-top: 1rem;
}

.heading-container {
  display: flex;
  align-items: center;
}

.head {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 28px;
}

.hint {
  font-weight: 600;
}