.space-membership-container {
  height: 100%;

  .membership-dummy-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 30%);

    .dummy-title-container {
      display: flex;
      align-items: center;

      .subheading {
        font-size: 2.4rem;
        font-weight: 500;
        color: #B7B5C6;
        margin-left: 1rem;
      }
    }

    .description {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #B7B5C6;
      margin: 0.8rem 0;
      max-width: 56.9rem;
    }
  }
}