.header-wrapper {
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title-container {
      display: flex;
      align-items: center;
    }

    .add-btn {
      margin-left: 1rem;
      border-radius: 3rem;
      border-color: 1px solid rgba(213, 101, 63, 1);
      color: rgba(213, 101, 63, 1);
    }
  }

  .category-wrapper {
    display: flex;
    align-items: center;
    margin-top: 3.2rem;

    .add-category-card {
      width: 20.6rem;
      height: 13.6rem;
      border: 1px dashed rgba(213, 101, 63, 1);
      border-radius: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.6rem;
      flex: 0 0 auto;

      .btn-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          font-size: 1.6rem;
          font-weight: 700;
          margin-top: 0.8rem;
        }
      }
    }

    .category-card-wrapper {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .card {
        margin-right: 1.6rem;
        flex: 0 0 auto;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}