.input-label {
  font-size: 1.4rem;
  color: #b7b5c6;
}

.input-container {
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
  .admin-wallet-input {
    font-size: 1.4rem !important;
    padding: 1.2rem 1.6rem !important;
    border-radius: 0.4rem !important;
    margin-top: 0.8rem;

    &.error-input {
      border: 1px solid red;

      &:hover {
        border: 1px solid red;
      }
    }
  }

  .error {
    color: red;
    font-size: 1.2rem;
  }
}
