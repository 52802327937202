.payment-request-wrapper {
  .payment-request-wrapper-title {
    color: #000000;
  }

  .request-header {
    width: 100%;
    padding: 2rem 3.7rem 1.6rem 3.6rem;
    margin-top: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 41px;

    .date-status-container {
      display: flex;
      align-items: center;

      .date-title {
        font-weight: 600;
        font-size: 16px;
      }

      .status-title {
        margin-left: 14rem;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .amount-container {
      display: flex;
      align-items: center;

      .amount-title {
        margin-right: 4rem;
        font-weight: 600;
        font-size: 1.6rem;
      }

      .action-title {
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }

  .dummy-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50rem);

    span {
      color: #575757;
      opacity: 0.4;
      font-size: 1.6rem;
    }
  }
}