.slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dummy-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;

  .dummy {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dummy-text {
      margin-top: 3.2rem;
      margin-bottom: 2.4rem;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #B7B5C6;
    }

    .dummy-content {
      font-weight: 400;
      font-size: 2rem;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #B7B5C6;
    }
  }
}

.card-container {
  margin-top: 2.4rem;
}

.pagination-style {
  width: 100%;
  text-align: right;
  margin-top: .8rem;
}