.add-game-wrapper {
  width: 100%;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 12px;
  padding: 2.4rem;

  h4 {
    font-weight: 600;
    line-height: 2.8rem;
    margin-bottom: 2.4rem;
  }
}