.game-card-container {
  width: 100%;
  height: 18rem;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  margin-bottom: 2.4rem;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    img {
      filter: blur(2.5px);
    }

    .game-details {
      .game-name-wrapper {
        transform: translateX(0%);
      }
    }
  }

  .game-details {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    padding: 1.2rem;

    .game-name-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      transform: translateX(-102%);
      transition: all 0.2s ease-in;

      h4 {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 8px;
      }

      img {
        width: 3.2rem;
        height: 3.2rem;
        filter: none;
        border-radius: 50%;
      }
    }
  }

  img {
    transition: all 0.2s ease-in-out;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
