.reaction-container {
  display: flex;
  border-radius: 1.5rem;
  background: #6E6AF0;
  padding: 0.4rem 0.8rem;

  .reaction-count-wrapper {
    display: flex;
    align-items: center;
    padding: 0.4rem;
    margin-right: 0.4rem;

    &:last-child {
      margin-right: 0;
    }

    .count-text {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 120%;
      color: #FFF;
      margin-left: 0.4rem;
    }
  }
}