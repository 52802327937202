.game-params {
  margin-bottom: 1rem;
}

.game-banner-uploader {
  width: 100%;
  height: 29rem;
  margin-bottom: 2.4rem;
}

.game-image-and-icon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .game-image {
    width: 22rem;
    height: 26rem;
    margin-right: 2.4rem;
    margin-bottom: 2.4rem;

    @media (max-width: 400px) {
      margin-right: 0;
    }
  }

  .game-icon {
    width: 19rem;
    height: 19rem;
    margin-bottom: 2.4rem;
  }
}

.bxo-form-control {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.4rem;

  @media (max-width: 839px) {
    grid-template-columns: repeat(1, 100%);
  }

  .form-control-100 {
    width: 100%;
    grid-column: 1/-1;
  }

  .add-param-btn {
    text-align: right;
  }

  .placement-point-control {
    display: flex;
    align-items: center;
  }

  .game-param-group {
    @media (max-width: 839px) {
      width: 100%;
      margin-bottom: 2.4rem;
      border-bottom: 1px solid #e1e1e1;
    }

    .form-control-50 {
      width: 100%;
      display: flex;

      @media (max-width: 839px) {
        flex-direction: column;
      }

      .rs-form-group {
        width: 100%;
        margin-bottom: 2.4rem;
      }

      .game-param-delete {
        width: 3rem;
        background: #6e6af0;
        border-radius: 4px;
        margin-left: 2.4rem;
        padding: 0.7rem;
        align-self: center;
        display: flex;
        justify-content: center;

        p {
          display: none;
          margin-left: 0.8rem;
          color: #ffffff;
          word-break: break-all;
        }

        @media (max-width: 839px) {
          margin-bottom: 2.4rem;
          margin-left: 0;
          width: 100%;

          p {
            display: inline-block;
            max-width: 12.1rem;
            text-align: left;
            font-weight: 500;
          }
        }
      }
    }
  }

  .placement-point-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5.4rem;

    @media (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 749px) {
      grid-template-columns: repeat(1, 100%);
    }
  }
}

.game-submit-cta {
  width: 100%;
  text-align: right;

  @media (max-width: 749px) {
    margin-top: 2.4rem;
  }
}

.game-form-title {
  font-size: 2rem;
  line-height: 2.3rem;
  font-weight: 600;
  margin: 2.4rem 0;
}