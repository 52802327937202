.tournament-details-header {
  @include header-reset;

  h3 {
    color: #1b1952;
    @include font-semi-bold;
  }

  .mods-menu {
    margin-right: 2.4rem;
  }
}
