.redeem-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .name {
    width: 100%;
    max-width: 11rem;
  }

  .email {
    width: 100%;
    max-width: 20rem;
  }

  .id {
    width: 100%;
    max-width: 18rem;
  }

  .amount {
    width: 100%;
    max-width: 10rem;
  }

  .status {
    width: 100%;
    max-width: 10rem;


    &.green-toggle {
      .rs-dropdown-toggle {
        color: green;
      }
    }

    &.red-toggle {
      .rs-dropdown-toggle {
        color: red;
        background-color: #f8e7e7;
      }
    }
  }

  .red-status {
    .rs-dropdown-item-content {
      color: red;
    }
  }
}