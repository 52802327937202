.rich-text-editor {
  .ql-toolbar {
    border-radius: 1rem 1rem 0 0;
    border: 1px solid rgba(98, 103, 107, 0.4) !important;
  }

  .ql-container {
    border-radius: 0 0 1rem 1rem;
    border: 1px solid rgba(98, 103, 107, 0.4) !important;
    border-top: none !important;
  }
}
