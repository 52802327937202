.slider-wrapper {
  width: 100%;
  cursor: pointer;
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  border-radius: 20px;
  margin-bottom: 1.6rem;
  height: 23.7rem;

  &:hover {
    .banner-container {
      .hover-on {
        opacity: 1;
      }
    }
  }

  .banner {
    height: 23.7rem;
  }

  .banner-container {
    .hover-on {
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.2);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      position: absolute;
      width: 100%;
      top: 0;
      transition: all 0.3s ease-in-out;


      .edit-delete {
        padding: 1rem 2.4rem;
        display: flex;
        justify-content: flex-end;

        .delete {
          margin-left: 2.4rem;
        }
      }
    }


    .content-container {
      width: 100%;
      height: 5.2rem;
      position: absolute;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(5px);

      .toggle {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 2.4rem;

        .toggle-style {
          &.rs-btn-toggle {
            background-color: red;
            height: 3rem;
            width: 5rem;
            border-radius: 2rem;

            &::after {
              width: 24px;
              height: 24px;
              left: 3px;
              top: 3px;
              border-radius: 11px;
            }

            &.rs-btn-toggle-checked {
              background-color: #6e6af0;

              &::after {
                left: 100%;
                margin-left: -27px;
              }
            }
          }
        }
      }
    }
  }
}
