.challenge-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-cta {
    display: flex;
    align-items: center;

    .mods-menu {
      margin-right: 2.4rem;
    }
  }
}
