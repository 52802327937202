.user-profile-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;
  }

  .user-detail-media-wrapper {
    display: flex;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;
      margin-left: 1.6rem;
      word-break: break-all;

    }

    .user-profile-img {
      width: 10rem;
      height: 10rem;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      flex: 0 0 auto;

      .profile-uploader {
        position: absolute;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .user-profile-cta-wrapper {
    display: flex;
    gap: 1.6rem;
  }
}

.user-account-wrapper {
  width: 100%;
  margin-top: 4rem;

  .user-account-settings {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #b7b5c6;
  }

  .user-account-details {
    width: 100%;
    display: flex;
    margin-top: 1.6rem;
    gap: 2.4rem;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .user-details-form-control-group {
      width: 50%;
      position: relative;

      @media (max-width: 800px) {
        width: 100%;
      }

      .rs-control-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }

      .rs-input {
        margin-top: 0.8rem;
      }
    }
  }
}