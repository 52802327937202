.game-store-list-wrapper {
  .game-store-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-wrapper {
      .game-store-status {
        width: 16rem;
        margin-right: 1.6rem;
      }
    }
  }

  .games-store-container {
    width: 100%;
  }

  .empty-games-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-games-flex-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}