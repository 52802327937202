.tab-container {
  margin-bottom: 2.4rem;
}

.membership-grid-form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.4rem;
  margin-top: 2.4rem;

  @media (max-width: 839px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .field-container {
    margin-top: 1rem;
  }

}

.add-button {
  text-align: right;
  margin-top: 2.4rem;
}

.tourney-rules-form {
  display: flex;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 839px) {
    flex-direction: column;
  }

  .rs-form-group {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .delete-rules-btn-container {
    @media (max-width: 839px) {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 2.4rem;
      margin-bottom: 2.4rem;
      width: 100%;
    }

    .delete-rules-btn {
      margin-left: 1rem;
      margin-top: 0.4rem;

      p {
        display: none;
      }

      @media (max-width: 839px) {
        margin-left: 0;
        width: 100%;

        p {
          display: inline-block;
          margin-left: 0.5rem;
        }
      }
    }
  }
}