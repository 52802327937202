.card-wrapper {
  position: relative;
  height: 8rem;
  overflow: visible;

  &.rs-panel {
    border-top: 1px solid rgba(187, 190, 204, 0.2);
    border-radius: 0;
    overflow: visible;
  }

  .content {
    width: 100%;
    height: 8rem;
    padding: 0 4.1rem 0 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.container {
      padding: 0 4.6rem 0 2.4rem;
    }

    .date-status-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 25rem;

      &.wrapper {
        width: 26rem;
      }

      .rs-tag {
        padding: 0.8rem 1.6rem;
        background-color: #ffaf01;
        color: #ffffff;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .amount-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 14rem;

      .dropdown {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}