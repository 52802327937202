.xo-product-wrapper {
  margin-top: 1.5rem;
  border-radius: 1.2rem;

  .product-list-header {
    padding: 1.6rem 10rem 0.8rem 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .xo-product-header {
      font-size: 1.4rem;
    }
  }

  .divider {
    padding: 0 1.6rem;

    hr {
      margin: 0;
    }
  }

  .xo-product-list-wrapper {
    padding: 1.6rem;
  }
}