.provisional-tournament-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .provisional-tournament-btn-container {
    display: flex;
    align-items: center;

    .provisional-tournament-btn {
      background-color: red;
      color: white;
      padding: 1rem 4rem;
      @include font-bold;

      &:last-child {
        background-color: green;
        margin-left: 2rem;
      }
    }
  }
}
