.input-shaded-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .input-shaded-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1b1952;
    margin-bottom: 0.8rem;
  }

  .input-shaded-text-wrapper {
    width: 100%;
    position: relative;

    .input-shaded {
      width: 100%;
      padding: 1.6rem;
      border: none;
      outline: none;
      background: #f5f5f5;
      border-radius: 6px;
      color: #1b1952;
    }

    .shaded-input-icon,
    .shaded-input-button {
      position: absolute;
      display: flex;
      height: 100%;
      width: 4.8rem;
      right: 0;
      top: 0;
      justify-content: center;
      align-items: center;

      & + .input-shaded {
        padding-right: 4.8rem;
      }
    }

    .shaded-input-button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
    }
  }
}
