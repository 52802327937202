.rank-input-wrapper {
  width: 100%;
  border-radius: 8px;
  padding: 1rem 1.6rem;
  display: flex;
  align-items: center;

  .rs-input {
    padding-right: 1.1rem !important;
  }

  .rank-name {
    margin: 0;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #b7b5c6;
    width: 3.1rem;
    margin-right: 1.8rem;
  }
}