.space-product-card {
  cursor: pointer;
  position: relative;
  border: 1px solid #e1e1e1;
  border-radius: 2rem;
  overflow: hidden;
  margin-bottom: 2.4rem;

  &:hover {
    border: 1px solid #6e6af0;

    .on-hover {
      opacity: 1;
    }
  }

  .on-hover {
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: absolute;
    width: 100%;
    top: 0;
    padding: 1rem 2.4rem;
    transition: all 0.3s ease-in-out;

    .status {
      color: #e33342;
      text-transform: capitalize;
    }

    .edit-delete {
      display: flex;
      justify-content: flex-end;

      .delete {
        margin-left: 2.4rem;
      }
    }
  }

  .image {
    height: 19.2rem;
    width: 100%;
  }

  .product-detail {
    height: 4.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;

    .name {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 16px;
    }

    .amount {
      display: flex;
      align-items: center;

      .price {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 16px;
        color: #81a330;
        margin-left: 4px;
      }
    }
  }
}