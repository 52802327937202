h3 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #1B1952;
  margin-bottom: 2.4rem;
}

.row-gap {
  margin-bottom: 2.4rem;

  .space-image-uploader {
    height: 29rem;
  }
}

.space-btn-container {
  display: flex;
  justify-content: flex-end;

  .btn {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 19px;
    padding: 1rem 2rem;
  }
}