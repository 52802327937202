	.category-header {
		display: flex;
		justify-content: space-between;
	}

	.product-details-container {
		margin-top: 2.4rem;
	}


	.update-category-icon {
		width: 100%;

		.rs-modal-title {
			font-size: 1.8rem;
			font-weight: 600;
			padding: 1px 0;
		}

		.upload-cat-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.category-icon-uploader {
				width: 10rem;
				height: 10rem;
			}

			.rs-btn {
				margin-top: 1rem;
			}
		}
	}