.game-info-detail-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;

  .game-store-header-wrapper {
    display: flex;
    align-items: flex-start;

    @media (max-width: 660px) {
      flex-direction: column;
    }

    .game-store-icon {
      width: 20rem;
      height: 20rem;
      border-radius: 2rem;
      overflow: hidden;
      flex: 0 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .game-store-description-wrapper {
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
      border-radius: 1.2rem;
      padding: 2.4rem;
      width: 100%;
      margin-left: 1.6rem;
      min-height: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 660px) {
        margin-left: 0;
        margin-top: 1.6rem;
      }

      .description-title {
        font-size: 1.8rem;
        font-weight: 600;
      }

      .game-store-description {
        font-size: 1.4rem;
        margin-top: 1.6rem;
        word-break: break-word;
      }
    }
  }

  .game-info-wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 2.4rem;

    @media (max-width: 1199px) {
      flex-direction: column;
    }

    .game-info-container {
      padding: 2.4rem;
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
      width: 45.6rem;
      border-radius: 2rem;

      &:first-child {
        margin-right: 4rem;
      }

      @media (max-width: 1199px) {
        width: 100%;
      }

      &.screenshots-container {
        width: calc(100% - 45.6rem);

        @media (max-width: 1199px) {
          width: 100%;
        }
      }

      .detail-title {
        font-size: 2rem;
        font-weight: 500;
      }

      .info-list-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.6rem;

        @media (max-width: 480px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .info-label {
          font-size: 1.6rem;
        }

        .info-value {
          font-size: 1.6rem;
          font-weight: 500;
          max-width: 25rem;
          word-break: break-word;
        }
      }

      .game-store-grid {
        margin-top: 1.6rem;

        .ss-container {
          width: 20rem;
          height: 20rem;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .game-store-ss-container {
          width: 100%;
          height: 20rem;
          border-radius: 0.4rem;
          overflow: hidden;
          margin-bottom: 2.2rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}