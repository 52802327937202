.dashboard-main-conatiner {
  width: 100%;
  height: 100%;

  .dashboard-header {
    width: 100%;
    display: flex;

    .dashboard-header-title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: 600;
    }
  }

  .dashboard-grid-container {
    width: 100%;
    margin-top: 2.4rem;
    padding-left: 0;
    padding-right: 0;

    .rs-col {
      margin-bottom: 2.4rem;
    }

    .common-dashboard-card {

      &:hover {
        color: #6e6af0;
      }

      .dashboard-card-wrapper {
        padding: 1.4rem 2.4rem 1.6rem 2.4rem;
        min-height: 18rem;

        .dashboard-card-title-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .dashboard-title-group {
            display: flex;
            align-items: center;

            .dashboard-card-title {
              margin-right: 0.4rem;
            }
          }

          .dashboard-icon-btn {
            background-color: transparent;
            border: none;
            outline: none;
          }

          .dashboard-card-title {
            margin: 0;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.9rem;
          }

          .fill-path {
            fill: currentColor;
          }

          .stroke-fill {
            stroke: currentColor;
          }
        }

        .dashboard-content-block {
          margin-top: 2rem;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;

          .campaign-link {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .param-count {
          margin: 9px 0 1.9rem 0;
          font-size: 7.2rem;
          line-height: 8.4rem;
          font-weight: 600;
        }

        .dashboard-card-footer {
          width: 100%;
          display: flex;
          align-items: center;

          .recently-added {
            margin: 0;
            font-size: 1.2rem;
            line-height: 1.4rem;
            white-space: nowrap;
            color: #B7B5C6;
          }

          .recently-added-name,
          .not-found-data {
            margin: 0;
            font-size: 1.2rem;
            line-height: 1.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .recently-added-name {
            font-weight: 600;
          }
        }

        .coming-soon-dashboard {
          width: 100%;
          height: 12.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .redirect {
          cursor: pointer;
        }
      }
    }
  }
}