.manage-pins-container {
  width: 100%;

  .manage-pins-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .manage-pin-ctas {
      display: flex;

      .manage-pin-status {
        width: 15rem;
      }
    }
  }

  .manage-pins-content-wrapper {
    width: 100%;
    margin-top: 1.8rem;
  }
}