.wallet-card-wrapper {
  width: 36rem;
  height: 20rem;
  border-radius: 2rem;
  background-image: linear-gradient(98deg, #ec731d -6.85%, #ffaf00 87.16%);
  position: relative;
  padding: 1.8rem 2rem;

  @media (max-width: 548px) {
    width: 100%;
  }

  .overlay-image {
    position: absolute;
    right: 2rem;
    top: 0;
    z-index: 0;
  }

  .wallet-card-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wallet-card-header-entity {
      display: flex;
      align-items: center;

      .entity-value {
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        margin-left: 0.4rem;

        &.amount {
          font-size: 2rem;
          font-weight: 700;
        }
      }
    }
  }

  .wallet-card-options-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2.8rem;

    .option-container {
      display: flex;
      align-items: center;
      padding: 1.2rem 1.4rem;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 1rem;
      background-color: rgba(255, 255, 255, 0.1);
      flex-direction: column;
      z-index: 1;
      min-width: 8rem;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      span {
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
        margin-top: 0.4rem;
      }
    }
  }
}
