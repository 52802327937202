.add-creator-label {
    font-size: 2.4rem;
    font-weight: 600;
}

.form-container {
    margin-top: 2.4rem;

    .add-btn-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 2.4rem;

        .add-btn {
            background: #6e6af0;
            font-size: 1.6rem;
            font-weight: 600;
        }
    }

    .creator-flex-container {
        display: flex;

        .add-profile-container {
            width: 19rem;
            height: 19rem;
            flex: 0 0 auto;
        }

        .add-cover-container {
            margin-left: 2.4rem;
            width: 100%;
            height: 19rem;
        }
    }

    .creator-grid-form-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.4rem;
        margin-top: 2.4rem;

        @media (max-width: 839px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .field-container {
            margin-top: 1rem;
        }
    }
}