.coupons-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .coupon-redeem-status {
    .redeem-status-dropdown {
      border: 1px solid transparent;
      font-size: 1.6rem;
      font-weight: 600;
      border-radius: 0.6rem;
      margin-right: 1.6rem;

      .rs-btn-subtle {
        width: 14rem;
        display: flex;
      }

      &:hover {
        .rs-btn-subtle {
          color: #6e6af0;
        }
      }
    }
  }

  .dummy-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 7rem;

    .dummy {
      display: flex;
      flex-direction: column;
      align-items: center;

      .dummy-text {
        margin-top: 3.2rem;
        margin-bottom: 2.4rem;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #b7b5c6;
      }

      .dummy-content {
        font-weight: 400;
        font-size: 2rem;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: #b7b5c6;
      }
    }
  }
}

.code-copy-btn {
  padding: 0.4rem 0.8rem;
  margin-right: 0.5rem;
}

.delete-btn-action {
  color: #6e6af0;
  padding: 0.4rem 0.8rem;
  border: 1px solid #6e6af0;
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover {
    background-color: #6e6af0;
    color: white;
  }
}