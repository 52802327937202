.wallet-transaction-panel {
  margin-top: 2.4rem;

  .rs-panel-header {
    padding: 0;
  }

  .rs-panel-body {
    padding: 0;
  }

  .transaction-table-entry {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1.6rem 3.6rem;

    &.table-data {
      border-top: 2px solid rgba(187, 190, 204, 0.1);
    }

    .transaction-type {
      min-width: 13rem;
    }

    .transaction-date {
      min-width: 10rem;
    }

    .transaction-amount {
      display: flex;
      align-items: center;
    }
  }
}
