.add-tournament-form {
  .tournaments-uploads-wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 839px) {
      flex-direction: column;
    }

    .tournament-icon {
      width: 29rem;
      height: 29rem;
      flex: 0 0 auto;

      @media (max-width: 839px) {
        width: 100%;
      }
    }

    .tournament-banner {
      width: 100%;
      height: 29rem;
      margin-left: 2.4rem;

      @media (max-width: 839px) {
        margin-left: 0;
        margin-top: 2.4rem;
      }
    }
  }

  .tournament-grid-form-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
    margin-top: 2.4rem;

    @media (max-width: 839px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .tournament-grid-form-100 {
      grid-column: 1 / span 2;

      @media (max-width: 839px) {
        grid-column: 1;
      }

      h5 {
        font-size: 2rem;
        margin: 2.4rem 0;
      }

      .prize-tooltip {
        display: flex;
        align-items: center;

        .prize-head {
          margin-right: 1rem;
        }
      }

      .tourney-config-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.4rem;
        row-gap: 2.4rem;
        margin-bottom: 2rem;

        @media (max-width: 839px) {
          grid-template-columns: repeat(1, 100%);
          border-bottom: 1px solid #e1e1e1;
          padding-bottom: 2.4rem;
        }

        .rs-form-group {
          width: 100%;
          margin-bottom: 0;
        }

        .tourney-form-control-50 {
          display: flex;
          align-items: flex-start;
          width: 100%;

          @media (max-width: 839px) {
            flex-direction: column;
          }

          .delete-parameter-btn {
            margin-left: 2.4rem;
            margin-top: 0.4rem;

            &.adjust-btn {
              margin-top: 2.2rem;
            }

            p {
              display: none;
            }

            @media (max-width: 839px) {
              margin-left: 0;
              margin-top: 2.4rem;
              width: 100%;

              p {
                display: inline-block;
                margin-left: 0.5rem;
              }
            }
          }
        }
      }

      .tourney-rules-form {
        display: flex;
        align-items: flex-start;
        width: 100%;

        @media (max-width: 839px) {
          flex-direction: column;
        }

        .rs-form-group {
          width: 100%;
          margin-bottom: 2.4rem;
        }

        .delete-rules-btn-container {
          @media (max-width: 839px) {
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 2.4rem;
            margin-bottom: 2.4rem;
            width: 100%;
          }

          .delete-rules-btn {
            margin-left: 1rem;
            margin-top: 0.4rem;

            p {
              display: none;
            }

            @media (max-width: 839px) {
              margin-left: 0;
              width: 100%;

              p {
                display: inline-block;
                margin-left: 0.5rem;
              }
            }
          }
        }
      }

      .form-image-100 {
        width: 100%;
        height: 20.4rem;
        margin-bottom: 2.4rem;
      }

      .tournament-config-wrapper {
        margin-bottom: 2rem;
        padding-bottom: 2.4rem;

        @media (max-width: 839px) {
          border-bottom: 1px solid #e1e1e1;
        }

        .tourney-round-number {
          font-size: 1.6rem;
          margin-bottom: 1rem;
          @include font-semi-bold;
        }

        .tourney-form-grid-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 2.4rem;
          row-gap: 2.4rem;

          @media (max-width: 839px) {
            grid-template-columns: repeat(1, 100%);
          }

          .rs-form-group {
            margin-bottom: 0;
          }
        }

        .remove-config-btn {
          text-align: center;
          margin-top: 2.4rem;

          @media (max-width: 839px) {
            .remove-btn {
              width: 100%;
            }
          }
        }
      }
    }

    .additional-requirement {
      display: flex;
      align-items: center;

      .additional-requirement-title {
        font-size: 2rem;
        margin: 0;
        margin-right: 0.5rem;
      }
    }
  }
}

.create-tourney-btn {
  text-align: right;
}
