.comment-card-wrapper {
  width: 100%;
  border-radius: 1.6rem;
  overflow: hidden;
  padding: 1.2rem 1.2rem 1rem 1.2rem;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
  border: 1px solid #3c3f43;

  .comment-icon {
    width: 3.3rem;
    height: 3.3rem;
    flex: 0 0 auto;
    background-color: #ffffff;
    color: #1b1952;
  }

  .comment-wrapper {
    margin-left: 1.2rem;

    .comment-posted-by {
      font-size: 1.6rem;
      @include font-semi-bold;
    }

    .comment-posted {
      font-size: 1.4rem;
      word-break: break-word;
    }

    .comment-options-container {
      display: flex;
      align-items: center;
      margin-top: 1.2rem;

      .comment-options {
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
      }

      .dot {
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        overflow: hidden;
        background-color: #3c3f43;
        margin: 0 0.5rem;
      }

      .show-reply {
        font-size: 1rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.reply-container {
  padding-left: 5.7rem;
}