.product-header {
  @include header-reset;
}

.create-tournament-btn-wrapper {
  display: flex;
  align-items: center;

  .create-ai-tourney-btn {
    background-image: linear-gradient(180deg, #703ff0 0%, #9865f0 100%);
    padding: 0.6rem 1.2rem 0.6rem 1.2rem;
    border-radius: 4rem;
    line-height: 20px;
    background-size: 200% auto;
    transition: 0.5s;
    display: flex;
    align-items: center;
    border: 2px solid #9865f0;
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: none;
      border: 2px solid #7953bb;
      background-image: linear-gradient(180deg, #592ec7 0%, #866bb5 100%);
    }

    .btn-text {
      margin-left: 0.4rem;
      font-weight: 700;
    }
  }

  .create-tourney-btn {
    margin-left: 2rem;
  }
}
