.vote-detail-card {
  border-radius: 12px;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
  padding: 2rem;
  margin-left: 1px;

  .vote-question {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.36px;
  }

  .vote-detail-container {
    display: grid;
    gap: 2.8rem;
    margin-top: 3.2rem;

    .vote-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.2rem;

      .vote-option {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }

      .option {
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0.32px;
      }
    }
  }


  .outside-rating-bar {
    overflow: hidden;
    width: 100%;
    height: 0.8rem;
    border-radius: 6px;
    background: rgba(204, 204, 221, 0.40);

    .inside-rating-bar {
      background-color: #6E6AF0;
      border-radius: 2rem;
      height: 100%;
    }
  }
}