.payment-history-wrapper {
  .request-header {
    width: 100%;
    padding: 2rem 3.7rem 1.6rem 3.6rem;
    margin-top: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date-status-container {
      display: flex;
      align-items: center;

      .date-title,
      .status-title {
        font-size: 1.6rem;
        font-weight: 600;
      }

      .status-title {
        margin-left: 14.4rem;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    .amount-container {
      display: flex;
      align-items: center;

      .amount-title {
        margin-right: 4.6rem;
        font-size: 1.6rem;
        font-weight: 600;
      }

      .action-title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }

  .dummy-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: calc(100vh - 50rem);

    span {
      color: #575757;
      opacity: 0.4;
      font-size: 1.6rem;
    }
  }
}