.xo-category-card-container {
    padding: 1.4rem 2.3rem 3.5rem 2.5rem;
    border-radius: 2rem;
    cursor: pointer;

    &:hover {
        .count-container>.count {
            color: #6e6af0;
            transition: color 1s;
        }

        .xo-category-card-header-container>.title {
            color: #6e6af0;
            transition: color 1s;
        }
    }

    .xo-category-card-header-container {
        display: flex;
        align-items: center;
        word-break: break-all;

        .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 60px;
            height: 60px;
            border-radius: 50%;

            .category-image {
                border-radius: 50%;
            }
        }

        .title {
            font-weight: 500;
            font-size: 1.6rem;
            margin-left: 2.4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .count-container {
        .count {
            font-size: 7.2rem;
            font-weight: 600;
        }
    }
}