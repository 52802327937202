.creator-link-container {
  width: 100%;

  .creator-link-modal-title {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 1px 0;
  }

  .copy-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.5rem;
    cursor: pointer;

    .copy-text {
      color: #B7B5C6;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.28px;
      margin-left: 0.5rem;
    }
  }

}