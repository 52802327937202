.rs-table.moderator-table-container {
    .title {
        font-size: 1.6rem;
        font-weight: 600;
    }

    .data-key {
        font-weight: 400;
        font-size: 1.4rem;
    }
}