.creator-container {
  .creator-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .creator-btn {
        padding: 1rem 3.4rem;
      }

      #my-profile-btn {
        padding: 1rem 2.4rem;
        border-radius: 8px;
        border: 1px solid #6E6AF0;
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;

        .profile-dot {
          width: .8rem;
          height: .8rem;
          background-image: linear-gradient(98.22deg, #6E6AF0 100%, #6E6AF0 100%);
          border-radius: 100%;
          animation: blink 1s ease-in-out infinite none;

          @keyframes blink {
            0% {
              box-shadow: 0 0 0 0 rgba(110, 106, 240, 0.4);
            }

            100% {
              box-shadow: 0 0 0 3px rgba(110, 106, 240, 0.3);
            }
          }
        }

        .btn-text {
          color: #6E6AF0;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          margin-left: 0.8rem;

        }
      }
    }
  }

  .creator-card-container {
    margin-top: 2.4rem;

    .creator-dummy-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 25rem);

      .dummy-text {
        font-weight: 500;
        font-size: 2.4rem;
        letter-spacing: 0.02em;
        color: #b7b5c6;
      }
    }
  }
}