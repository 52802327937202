.xoshop-wrapper {

  .header-container {
    display: flex;
    justify-content: space-between;
    background-color: inherit;

    .header-btns-container {
      display: flex;
      gap: 10px;

      .header-btn {
        font-size: 1.6rem;
        font-weight: 600;

        &:hover {
          background: #6e6af0;
        }
      }
    }
  }

  .xo-shop-category-card-main-container {
    margin-top: 2.4rem;

    .margin-btn {
      margin-bottom: 2.4rem;
    }
  }
}