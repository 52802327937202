.category-card-container {
  padding: 3.2rem 3.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 13.6rem;
  width: 25.1rem;
  border-radius: 1.2rem;
  background-color: rgba(21, 30, 43, 0.2);

  &:hover {
    background-color: rgba(213, 101, 63, 01);
    color: white;
  }

  .category-name {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 1.8rem;
      font-weight: 600;
    }

    .count {
      font-size: 3rem;
      font-weight: 700;
    }
  }

  .category-icon {
    width: 6rem;
    height: 6rem;
    position: relative;
    border-radius: 0.4rem;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
