.add-product-label {
  font-size: 2.4rem;
  font-weight: 600;
}

.form-container {
  margin-top: 2.4rem;

  .form-fields-container {
    margin-top: 3.2rem;

    .field-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .grid-container {
    margin-top: 3.2rem;

    .field-container {
      margin-top: 1rem;
    }

    .parent-product-entities-wrapper {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      @media (max-width: 839px) {
        padding-bottom: 1rem;
        border-bottom: 1px solid grey;
        flex-direction: column;
      }

      .rs-form-group {
        margin-bottom: 0 !important;
        width: 100%;

        &:first-child {
          margin-right: 2rem;

          @media (max-width: 839px) {
            margin-right: 0;
          }
        }
        @media (max-width: 839px) {
          &:last-child {
            margin-top: 1rem;
          }
        }
      }
    }

    .remove-parent-variant-cta {
      margin: 2.2rem 0 0 1rem;
      padding: 1.2rem;
      flex: 0 0 auto;

      @media (max-width: 839px) {
        width: 100%;
      }
    }

    .add-parent-variant-cta {
      margin-top: 1rem;

      @media (max-width: 839px) {
        width: 100%;
      }
    }

    .child-product-entities-wrapper {
      margin-top: 1rem;

      .product-id-container {
        margin-top: 1rem;
        display: flex;
        align-items: flex-end;

        @media (max-width: 839px) {
          flex-direction: column;
        }

        .rs-form-group {
          margin-bottom: 0 !important;
          width: 50%;

          @media (max-width: 839px) {
            width: 100%;
          }
        }

        .get-variants-cta {
          margin-left: 1rem;

          @media (max-width: 839px) {
            margin-top: 1rem;
            margin-left: 0;
            width: 100%;
          }
        }
      }

      .variants-wrapper {
        margin-top: 1rem;
        display: flex;
        align-items: center;

        @media (max-width: 839px) {
          flex-direction: column;
        }

        .rs-form-group {
          margin-bottom: 0 !important;
          width: 100%;

          &:first-child {
            margin-right: 1rem;

            @media (max-width: 839px) {
              margin-right: 0;
            }
          }
          @media (max-width: 839px) {
            &:last-child {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }

  .add-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.4rem;

    .add-btn {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;

    .upload-image-size-container {
      width: 190px !important;
      height: 190px !important;
    }
  }
}

.affiliate-container {
  .title {
    font-size: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .add-btn {
    margin-top: 1.4rem;
  }
}
