.space-card {
  border: 1px solid #e1e1e1;
  border-radius: 1.2rem;
  overflow: hidden;
  margin-bottom: 2.4rem;
  cursor: pointer;
  position: relative;
  height: 24.5rem;

  &:hover {
    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.5);
  }

  .space-card-image {
    width: 100%;
    height: 24.5rem;
  }

  .space-card-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2.5px);
    width: 100%;
    height: 5rem;
    position: absolute;
    bottom: 0;

    .space-card-text {
      padding-left: 2.4rem;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 19px;
      color: #ffffff;
      text-transform: capitalize;
      margin-right: 4px;
      max-width: 40rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
