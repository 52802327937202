.admin-wallet-info-container {
  display: flex;
  align-items: center;
  margin-top: 3.2rem;

  .transaction-history-card {
    border: 1px dashed #d2d1fa;
    width: 36rem;
    height: 20rem;
    margin-left: 2.4rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .rs-panel-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 548px) {
      width: 100%;
    }

    .transaction-history-card-title {
      font-size: 1.4rem;
      color: #6e6af0;
      font-weight: 500;
    }
  }
}
