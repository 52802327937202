.product-header {
  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }
}

.product-list-container {
  width: 100%;
}