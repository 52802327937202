.pro-pass-users-table-wrapper {
  margin-top: 2rem;

  .pass-status-chip {
    color: white;
    text-align: center;
    border-radius: 0.4rem;
    font-weight: 600;

    &.active-pass-chip {
      background-color: green;
    }

    &.expired-pass-chip {
      background-color: red;
    }
  }
}