.brand-enquiry-list-wrapper {
  margin-top: 2rem;

  .view-link .rs-table-cell-content {
    padding: 8px 10px;
  }

  .view-link .rs-btn-sm {
    padding: 5px 0px;
    color: rgba(110, 106, 240, 1) !important;
  }
}
