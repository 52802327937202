.shorts-detail-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: flex-start;
  }

  .shorts-section {
    width: 100%;
    max-width: 60rem;

    @media (min-width: 1200px) {
      padding-right: 2.4rem;
    }

    .shorts-player-container {
      height: calc(100vh - 20rem);
    }
  }
}

.comment-section {
  width: 100%;
  position: relative;

  @media (min-width: 1200px) {
    border-left: 1px solid #e1e1e1;
  }
}