.detail-card-container {
  display: flex;
  width: 100%;
  margin-top: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media (min-width: 950px) {
    display: grid;
    grid-template-columns: 30rem auto;
  }

  .creator-detail {
    padding: 1.2rem;
    border-radius: 1.2rem;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);

    .about {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .description {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.02em;
      line-height: 20px;
      letter-spacing: 2%;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}