.login-page {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  .login-switch-wrapper {
    width: 100%;
    height: 100%;
    max-height: 72rem;
    max-width: 136.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(-10px 7px 40px rgba(100, 100, 111, 0.4));

    .hub-login-welcome {
      width: 100%;
      height: 100%;
      background-color: #424090;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;

      @media (max-width: 800px) {
        display: none;
      }

      .welcome-user {
        max-width: 48rem;
        width: 100%;
        max-height: 45rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        h1 {
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
          margin: 0 0 1rem 0;
        }

        .welcome-image {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
