.space-list-conatiner {
  width: 100%;

  .space-card-container {
    width: 100%;
    margin-bottom: 2rem;
    transition: all 0.2s ease-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
      border: 1px solid $color-primary;
      color: $color-primary;
    }

    .space-image {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    .space-name {
      font-size: 1.5rem;
      @include font-semi-bold;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 0 1rem;
      cursor: pointer;
      height: 2.2rem;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
    }
  }
}
