.shorts-player-wrapper {
  width: 100%;

  .shorts-video {
    position: relative;
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;

    video {
      width: 100%;
      height: auto;
      max-height: calc(100vh - 20rem);
      margin: 0;
      vertical-align: bottom;
    }

    .shorts-control-container {
      width: 100%;
      display: flex;
      background-color: lighten(#6e6af0, 28%);
      padding: 0.1rem 0;

      .play-control {
        padding: 0.4rem 0.8rem;
        width: 2.8rem;
        border: 1px solid #6e6af0;
        background-color: lighten(#6e6af0, 25%);
        border-radius: 4px;
      }

      .sound-control {
        padding: 0.4rem 0.8rem;
        width: 2.8rem;
        border: 1px solid #6e6af0;
        background-color: lighten(#6e6af0, 25%);
        border-radius: 4px;
      }

      .seeker-container {
        width: calc(100% - 5.6rem);
        display: flex;
        align-items: center;

        .seeker {
          height: 1rem;
          background-color: rgba(255, 255, 255, 0.5);
          width: 100%;
          border-radius: 2rem;
          overflow: hidden;
          border: 1px solid #6e6af0;
          margin: 0 0.5rem;

          .seeker-complete {
            height: 100%;
            width: 10%;
            background-color: #6e6af0;
          }
        }
      }
    }
  }

  .shorts-caption-wrapper {
    width: 100%;
    max-width: 50rem;
    padding: 1rem;

    .caption-title {
      font-weight: 600;
      margin: 0;

      &::after {
        content: " :";
      }
    }

    .caption-text {
      margin: 0;
    }
  }
}
