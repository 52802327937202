.intermediate-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .state-buffer {
      display: inline-block;
      width: 40px;
      height: 40px;

      &::after {
        content: " ";
        display: block;
        width: 32px;
        height: 32px;
        margin: 0;
        border-radius: 50%;
        border: 4px solid #fff;
        border-color: rgba(110, 106, 240, 1) transparent rgba(110, 106, 240, 1) transparent;
        animation: buffer 1.2s linear infinite;
      }
    }

    .state-name {
      font-size: 2rem;
      color: #6e6af0;
    }
  }

  .by-line {
    font-size: 1.4rem;
    color: #6e6af0;
  }
}

.add-shorts-preview {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #6e6af0;
  border-radius: 0.8rem;

  .file-name-container {
    display: flex;
    align-items: center;

    .file-name {
      font-size: 1.4rem;
      color: #6e6af0;
      font-weight: 600;
      margin-left: 1rem;
    }
  }
}

.add-shorts-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20rem;
  border: 1px solid #6e6af0;
  border-radius: 0.8rem;

  .add-btn {
    color: #6e6af0;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.input-caption {
  margin-top: 2rem;
  font-size: 1.4rem !important;
}

@keyframes buffer {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}