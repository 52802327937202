.rivalry-zone-details-container {
  width: 100%;

  .rivalry-zone-header {
    @include header-reset;

    h3 {
      margin-bottom: 0;
    }

    .mods-menu {
      margin-right: 2.4rem;
    }
  }
}
