.rs-container.main-container {
  height: 100vh;
  width: 100%;
  display: block;
  position: fixed;
}

.rs-header.app-top-bar {
  display: flex;
  height: 6.8rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5rem;
  position: fixed;
  width: 100%;
  z-index: 9;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);

  .switch-roles {
    margin-right: 2.4rem;

    .rs-dropdown-toggle {
      width: 10rem;
      border-radius: 1.9rem;
    }

    .rs-dropdown-menu {
      margin-right: 0;
    }
  }

  .toggle-theme {
    margin-right: 2.4rem;

    .toggle-light-icon {
      margin-top: 0.4rem;
      margin-right: 0.4rem;
    }

    .toggle-dark-icon {
      margin-top: 0.4rem;
      margin-left: 0.4rem;
    }
  }

  .faq {
    font-size: 1.8rem;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: #6e6af0;
    }
  }

  .profile-wrapper {
    display: flex;

    .top-nav-cta {
      margin-left: 3.6rem;
      padding: 0;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:hover {
        color: #6e6af0;
      }

      .fill-path {
        fill: currentColor;
      }

      .stroke-fill {
        stroke: currentColor;
      }

      .rs-dropdown-menu {
        padding: 0.8rem 0;
        width: 20rem;

        .rs-dropdown-item {
          padding: 1.2rem 2.4rem;
        }
      }

      .rs-dropdown-item {
        display: flex;
        align-items: center;

        .nav-menu-list-item {
          margin: 0;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }

        .nav-menu-list-icon {
          margin-right: 0.8rem;
        }
      }
    }
  }
}

.rs-sidebar.app-sidebar {
  height: 100vh;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  background-color: $color-primary;

  .rs-sidenav {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.rs-sidenav-collapse-in {
      height: calc(100vh - 203px);
    }

    &.rs-sidenav-collapse-out {
      height: calc(100vh - 193px);

      & + .rs-navbar.nav-toggle {
        padding: 1rem 0.7rem;

        .nav-toggle-wrapper {
          .nav-logo-container {
            width: 0;
          }

          .nav-toggle-button {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .rs-navbar.nav-toggle {
    background-color: #635fd8;
    height: 4rem;
    padding: 1rem 2.4rem;
    transition: all 0.3s ease-in-out;

    .nav-toggle-wrapper {
      width: 100%;
      display: flex;

      .nav-logo-container {
        text-align: center;
        width: calc(100% - 2rem);
        transition: all 0.3s ease-in-out;

        img {
          max-width: 9.1rem;
          width: 100%;
        }
      }

      .nav-toggle-button {
        background-color: transparent;
        padding: 0 1rem;
        margin: 0;
        border: none;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .rs-sidenav .rs-nav-item .rs-nav-item-content {
    color: #ffffff;
  }

  .rs-sidenav-header {
    .sidebar-logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;

      .sidebar-image-container {
        position: relative;
        margin-top: 1rem;

        .edit-icon {
          position: absolute;
          bottom: 0;
          left: 44px;
          background: #3a37a7;
          padding: 0.4rem;
          border: 1px solid white;
          border-radius: 50%;
        }
      }

      .name {
        color: #ffffff;
        margin-top: 1.6rem;
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .rs-sidenav-header.sidebar-header {
    width: 100%;
    padding: 2.4rem;
    transition: all 0.3s ease-in-out;

    &.collapsed {
      padding: 0;
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      transition: all 0.3s ease-in-out;

      .sidebar-profile-container {
        .profile-image {
          width: 50px;
          height: 50px;
        }

        .user-name {
          opacity: 0;
        }
      }
    }

    .sidebar-profile-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      overflow: hidden;

      .profile-image {
        width: 60px;
        height: 60px;
        background-color: #ffffff;
        border-radius: 50%;
        overflow: hidden;
        padding: 2px;
        margin-top: 1.6rem;
        transition: all 0.3s ease-in-out;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .user-name {
        transition: all 0.3s ease-in-out;
        color: #ffffff;
        font-weight: 600;
        font-size: 1.6rem;
        margin-top: 1.6rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 21.2rem;
      }
    }
  }
}

.rs-sidebar.app-sidebar.collapsed + .rs-content.main-content {
  width: calc(100% - 56px);
}

.rs-content.main-content {
  height: calc(100vh - 6.8rem);
  width: calc(100% - 260px);
  padding: 2.4rem;
  overflow-y: auto;
  position: relative;
  margin-left: auto;
  transition: all 0.2s ease-in;
  margin-top: 6.8rem;
}

.rs-nav.nav-item-wrapper {
  .rs-sidenav-item {
    position: relative;

    .nav-content {
      margin: 0;
      display: inline-block;
      font-weight: 500;
      color: #ffffff;
    }

    &:hover {
      background-color: #4b48a3 !important;
      color: #ffffff !important;

      svg.nav-icon.shorts .fill-path {
        fill: #4b48a3;
      }
    }

    &.active {
      background-color: #fcfcfc;

      .nav-content {
        color: $color-primary !important;
      }

      &:hover,
      &:active,
      &:focus {
        color: $color-primary !important;
        background: #ffffff !important;

        svg.nav-icon.shorts .fill-path {
          fill: #ffffff;
        }
      }

      svg.nav-icon {
        .fill-path {
          fill: #4b48a3;
        }

        .stroke-fill {
          stroke: #4b48a3;
        }

        &.shorts {
          .fill-path {
            fill: #ffffff;
          }
        }
      }
    }

    svg.nav-icon {
      margin-right: 20px;
      position: absolute;
      left: 20px;
      top: 15px;
      line-height: 1.25;

      &.shorts {
        .fill-path {
          fill: rgb(110, 106, 240);
        }
      }
    }
  }
}

.rs-btn-primary {
  word-break: normal;
  line-break: anywhere;
  white-space: normal;
  font-weight: 500;
}

.rs-btn-primary:not(.rs-btn-disabled):hover {
  background-color: #635fd8;
}

.rs-picker-select-menu-item {
  color: #b7b5c6;
}

.rs-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rs-modal-open {
  #root {
    transition: all 0.3s ease-in-out;
    filter: blur(4px);
  }
}

.rs-modal {
  margin-top: 0;

  .rs-modal-dialog {
    width: 100%;
  }
}
