.game-store-card-container {
  width: 100%;
  height: 25.1rem;
  padding: 0.8rem;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  margin-bottom: 2.4rem;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  border-radius: 20px;
  cursor: pointer;

  .image-container {
    width: 100%;
    height: 17.1rem;
    border-radius: 0.2rem;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .game-chip {
      padding: 0.4rem 1.2rem;
      background-color: #ffd23f;
      border-radius: 2rem;
      color: white;
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-weight: 500;

      &:last-child {
        margin-left: 0.5rem;
      }

      &.delete-icon {
        background-color: #ff4d4f;
      }

      &.published {
        background-color: #007e51;
      }

      &.rejected {
        background-color: #ff4d4f;
      }
    }
  }

  .game-store-info-container {
    .info-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 1rem;

      .label {
        font-size: 1.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .value {
        color: rgba(152, 152, 152, 1);
        font-size: 1.6rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}