.rs-notification {
  .rs-notification-item-wrapper {
    max-width: 29rem;

    .rs-notification-item {
      .rs-notification-item-close {
        color: #ffffff;
      }

      &.rs-notification-error {
        .rs-notification-item-content {
          background-color: #f44336;

          .rs-notification-title {
            color: #ffffff;
          }

          .rs-notification-description {
            color: #ffffff;
          }

          .rs-icon {
            color: #ffffff;
          }
        }
      }

      &.rs-notification-success {
        .rs-notification-item-content {
          background-color: #4caf50;

          .rs-notification-title {
            color: #ffffff;
          }

          .rs-notification-description {
            color: #ffffff;
          }

          .rs-icon {
            color: #ffffff;
          }
        }
      }

      &.rs-notification-warning {
        .rs-notification-item-content {
          background-color: #ff9800;

          .rs-notification-title {
            color: #ffffff;
          }

          .rs-notification-description {
            color: #ffffff;
          }

          .rs-icon {
            color: #ffffff;
          }
        }
      }

      &.rs-notification-info {
        .rs-notification-item-content {
          background-color: #2196f3;

          .rs-notification-title {
            color: #ffffff;
          }

          .rs-notification-description {
            color: #ffffff;
          }

          .rs-icon {
            color: #ffffff;
          }
        }
      }
    }
  }
}
