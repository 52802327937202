.space-shorts-main-wrapper {
  width: 100%;

  .space-shorts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $color-primary-text;
      font-weight: 600;
    }
  }

  .space-shorts-wrapper {
    margin-top: 2.4rem;
  }
}
