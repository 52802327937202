.comment-mention-wrapper {
  padding: 0.5rem 1rem;
  background-color: #ececff;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 1rem;
  margin: 0.5rem 0 0.5rem 2.4rem;
  color: #1b1952;

  .close-icon {
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
