.rivalry-zone-form {

  .rivalry-zone-media-container {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .tournament-icon {
      width: 23rem;
      height: 29rem;
      flex: 0 0 auto;
      margin-right: 2.4rem;
    }

    .tournament-banner {
      width: 100%;
      height: 29rem;

      @media (max-width: 1200px) {
        margin-top: 2.4rem;
      }
    }
  }

  .rivalry-zone-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
    row-gap: 2.4rem;

    @media (max-width: 839px) {
      grid-template-columns: repeat(1, 100%);
    }

    .rs-form-group {
      margin-bottom: 0 !important;
    }

    .rivalry-zone-form-100 {
      grid-column: 1 / span 2;

      @media (max-width: 839px) {
        grid-column: 1;
      }
    }

    h5 {
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
    }

    .rivalry-zone-form-50 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2.4rem;
      row-gap: 2.4rem;

      @media (max-width: 839px) {
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 2.4rem;
        border-bottom: 1px solid #e1e1e1;
        margin-top: 2rem;

        &.remove-border {
          border-bottom: 0;
        }
      }

      .rs-form-group {
        width: 100%;
      }

      .rivalry-zone-form-flex {
        display: flex;
        align-items: center;

        @media (max-width: 839px) {
          flex-direction: column;
        }

        .rs-form-group {
          width: 100%;
        }

        .delete-config-btn {
          margin-left: 1rem;
          margin-top: 0.4rem;

          &.adjust-btn {
            margin-top: 2rem;
          }

          p {
            display: none;
          }

          @media (max-width: 839px) {
            margin-top: 2rem;
            width: 100%;
            max-width: 83.9rem;
            margin-left: 0;

            p {
              display: inline-block;
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.form-submit-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.4rem;
}