.image-uploader {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;

  .upload-image {
    height: 29rem;
    border: none;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #62676B;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;

  .btn {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 19px;
    padding: 1rem 2rem;
  }
}