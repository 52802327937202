.pagination {
  width: 100%;
  background-color: transparent;
  padding: 2.4rem 0;
  text-align: center;
}

.rs-pagination.bxo-pagination {
  background-color: #f1f0fe;
  border-radius: 8px;
  padding: 0.9rem 1.6rem;

  .rs-pagination-btn {
    &:first-child {
      button {
        margin-left: 0;
      }
    }

    &:last-child {
      button {
        margin-right: 0;
      }
    }

    &.rs-pagination-btn-disabled {
      button {
        color: #A5A3F5;
      }
    }

    &.rs-pagination-btn-active {
      button {
        background-color: #6e6af0;
        border-radius: 4px;
        color: #ffffff;
        padding: .4rem;

      }
    }

    button {
      padding: 0;
      font-weight: 400;
      color: #6E6AF0;
      font-size: 12px;
      line-height: 14px;
      min-width: 1.6rem;
      min-height: 1.6rem;
      margin-right: 0.8rem;

      span[title="Previous"] {
        margin-right: 0.8rem;

        &::before {
          content: "Previous";
        }

        i {
          display: none;
        }
      }

      span[title="Next"] {
        margin-left: 0.8rem;

        &::before {
          content: "Next";
        }

        i {
          display: none;
        }
      }
    }
  }
}