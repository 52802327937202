.product-redeem-request-card {
  background-color: rgba(21, 30, 43, 0.2);
  margin-bottom: 1.6rem;

  .rs-panel-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2rem;
  }

  .product-redeem-request-title-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    .product-redeem-request-image {
      width: 22rem;
      height: 14rem;
      overflow: hidden;
      position: relative;
      margin-right: 1.6rem;

      .icon {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .product-redeem-request-title {
      display: flex;
      flex-direction: column;
      width: 20rem;
      //flex-grow: 2;

      .product-redeem-request-name {
        font-size: 1.4rem;
        font-weight: 500;
      }
      .product-redeem-request-address{
        font-size: 1.4rem;
        font-weight: 500;
        max-width: 40rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .product-redeem-request-amount {
        display: flex;
        align-items: center;

        .amount {
          font-size: 1.6rem;
          margin-right: 0.4rem;
          font-weight: 600;
        }
      }
    }
  }

  .product-redeem-request-action-container {
    .delete-btn {
      font-size: 1.4rem;
      color: rgba(98, 103, 107, 1);
      padding: 0.8rem 1.2rem;
      border: none;
      margin-right: 1.6rem;
    }

    .edit-btn {
      font-size: 1.4rem;
      color: black;
      padding: 0.8rem 2rem;
      border: 1px solid rgba(213, 101, 63, 0.4);
    }
  }
}
