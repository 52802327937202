.space-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .space-ctas {
    display: flex;
    align-items: center;

    @media (max-width: 420px) {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .rs-input-group {
      margin-right: 1rem;

      @media (max-width: 420px) {
        margin-bottom: 1rem;
        width: 100%;
      }
    }

    .btn {
      flex-shrink: 0;
      padding: 1rem 1.6rem;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 28px;
    color: #1b1952;
  }

  .space-list-grid {
    width: 100%;
  }
}
