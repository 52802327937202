.space-post-header {
  margin: 2.4rem 0 1.6rem 0;
  border: 1px solid #3c3f43;
  border-radius: 8px;

  h5 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 23px;
    padding: 2.4rem 4rem;
    color: #6E6AF0;
  }
}