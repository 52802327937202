.header {
  width: 100%;
  padding: 1rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  span {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 19px;
  }
}