.space-post-pin-card {
  width: 100%;
  border-radius: 0.8rem;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 1rem;

  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(1);
  }

  .space-post-pin-card-image {
    width: 100%;
    height: 15rem;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;

    .empty-space-post-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .space-post-pin-card-content {
    width: 100%;
    margin: 0.8rem 0;
    padding: 0 0.4rem;
    height: 1.5rem;
    white-space: nowrap;

    p {
      margin: 0;
      font-size: 1.4rem;
      line-height: 1.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .space-post-pin-card-cta {
    background-color: transparent;
    padding: 0.8rem 0.4rem;
    width: 100%;
    border: 1px solid #6e6af0;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.8rem;
    }
  }
}
