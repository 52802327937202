.space-post-filter-container {
  padding: .9rem 2.4rem;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 18.2rem;

  h5 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 126%;
  }

  .filter-container {
    display: flex;

    .filter-option {
      margin-right: 5.5rem;
      width: auto;
      display: flex;
      margin-left: 1rem;
      flex-direction: column;
      margin-top: 1.4rem;

      p {
        margin-left: 1rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 126%;
      }
    }
  }
}