.loader-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(#000000, 0.8);
  left: 0;
  top: 0;

  .loader-icon {
    width: 7rem;
    height: 7rem;
    animation-name: icon-loader;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;

    img {
      width: 100%;
    }
  }
}

@keyframes icon-loader {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  90% {
    transform: rotate(720deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(720deg);
    transform-origin: center;
  }
}
