.image-preview-wrapper {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.6rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .image-preview-btn {
    background: none;
    position: absolute;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    right: 0;
    width: 20px;
    height: 20px;
    background: $color-primary;
    border-radius: 50%;
  }
}
