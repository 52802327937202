.creator-response-card {
  padding: 1.8rem 2rem;
  border-radius: 1.2rem;
  box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
  margin-top: 2.6rem;

  .creator-header-container {
    display: grid;
    grid-template-columns: repeat(6, minmax(10rem, 1fr));
    overflow-wrap: anywhere;

    .heading {
      font-size: 1.6rem;
      font-weight: 600;
      padding-bottom: 2rem;
      border-bottom: 1px solid rgba(183, 181, 198, 0.20);
    }

    .value {
      font-size: 1.4rem;
      font-weight: 400;
      padding-right: 2rem;
      padding-top: 2rem;
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 2rem;
      padding-top: 2rem;

      .accept-btn {
        padding: 0.7rem 2.4rem;
        width: fit-content;
      }
    }
  }

  .hub-admin {
    display: grid;
    grid-template-columns: repeat(5, minmax(10rem, 1fr));
    overflow-wrap: anywhere;
  }
}