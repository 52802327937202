.game-details-page-container {
  width: 100%;

  .game-title-with-cta {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    @media (max-width: 555px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .game-cta {
      margin-left: 1rem;

      @media (max-width: 720px) {
        .rs-btn {
          margin-bottom: 0.8rem;
        }
      }

      @media (max-width: 555px) {
        margin-left: 0;
      }
    }
  }
}