.challenges-details-wrapper {
  margin-top: 3.4rem;

  .rs-panel {
    border-radius: 1.2rem;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
  }

  .challenge-basic-info-panel {
    .rs-panel-body {
      display: flex;
      width: 100%;
      padding: 0;
    }

    .challenge-icon-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.8rem;
      width: 16rem;
      flex: 0 0 auto;
      background-image: linear-gradient(90deg, #536976 0%, #292e49 100%);

      .icon-container {
        width: 5.2rem;
        height: 5.2rem;
        border-radius: 100%;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .challenge-code {
        padding: 0.4rem;
        font-size: 1.2rem;
        border-radius: 0.4rem;
        color: white;
        margin-top: 0.8rem;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.2);
        @include font-bold;
      }
    }

    .challenge-description {
      padding: 2rem;
      width: 100%;
      line-height: 140%;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      word-break: break-word;
    }
  }

  .challenge-advance-info-container {
    display: flex;
    align-items: flex-start;
    margin-top: 2rem;

    .challenge-details-panel {
      &.rs-panel {
        // width: 45.6rem;
        width: 50%;
        border-radius: 1.2rem;
        box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
        flex: 0 0 auto;

        &.prize-distribution-panel {
          width: 50%;
          // width: 100%;
          flex: auto;
        }
      }

      &:not(:last-child) {
        margin-right: 2.4rem;
      }

      .detail-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 2rem;
          @include font-semi-bold;
        }

        .tournament-status {
          font-size: 1.6rem;
          text-transform: capitalize;
          color: #007e51;
        }
      }

      .detail-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;

        @media (max-width: 575px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .detail-text {
          font-size: 1.6rem;
          font-weight: 300;
        }
      }
    }
  }
}
