.space-post-card {
  border-radius: 1rem;
  height: 31.9rem;
  margin-bottom: 2.4rem;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);

  .space-post-card-image {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;
  }

  .empty-space-post-container {
    height: 23.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .post-image {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 23.2rem;
  }

  .status {
    position: absolute;
    top: 1.6rem;
    right: 3.6rem;
  }

  .status-tag {
    &.rs-tag {
      padding: 0.8rem 1.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 16px;
      text-transform: capitalize;
    }
  }

  .post-detail-wrapper {
    padding: 1.2rem 2.4rem 1.6rem 1.6rem;

    .space-post-detail {
      .avatar-name-status-time {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .avatar-name {
          display: flex;
          align-items: center;

          .avatar {
            width: 3rem;
            height: 3rem;
          }
        }

        .name-time {
          margin-left: 8px;
          display: flex;
          flex-direction: column;

          .user-name {
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 126%;
          }

          .post-time {
            font-weight: 400;
            font-size: 1rem;
            line-height: 126%;
          }
        }

        .post-option {
          width: auto;

          .option-btn {
            background-color: transparent;
            padding: 0;
          }
        }
      }
    }

    .comment {
      margin-top: 1.2rem;
      padding-left: 3.6rem;

      .post-text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 16px;
        text-overflow: ellipsis;
        max-width: 38rem;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}