.games-main-wrapper {
  .games-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;

    h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $color-primary-text;
      font-weight: 600;
    }

    @media (max-width: 635px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2.4rem;
    }
  }

  .games-wrapper {
    width: 100%;
  }

  .empty-games-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-games-flex-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
