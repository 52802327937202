.tournament-pin-card {
  width: 100%;
  border-radius: 0.8rem;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 1rem;
  position: relative;

  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(1);
  }

  .tournament-pin-card-image {
    width: 100%;
    height: 15rem;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tournament-pin-title-wrapper {
    width: 100%;
    margin: 0.8rem 0;
    padding: 0 0.4rem;
    height: 1.8rem;
    white-space: nowrap;

    p {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.8rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .tournamnet-pin-btn {
    background-color: transparent;
    padding: 0.8rem 0.4rem;
    width: 100%;
    border: 1px solid #6e6af0;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.8rem;
    }
  }

  .tournament-status {
    padding: 0.2rem 0.8rem;
    font-size: 1.2rem;
    color: white;
    border: 1px solid rgba(149, 157, 165, 0.2);
    @include font-semi-bold;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .tournament-premium-tag-wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.2rem 1.2rem;
    border-radius: 1.2rem;
    background-image: linear-gradient(98deg, #f48f48 -6.85%, #fecf69 87.16%);

    .tournament-premium-tag {
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
    }
  }
}