.image-upload-wrapper {
  width: 100%;
  height: 100%;
  border: 1px dashed rgba(98, 103, 107, 0.4);
  border-radius: 6px;
  cursor: pointer;

  &.error-state {
    border: 1px dashed red;
  }

  .label-wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .error-msg {
    color: red;
    font-size: 1rem;
    margin-left: 0.4rem;
  }
}
