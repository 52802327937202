.poll-form-wrapper {
  width: 100%;

  .field-container {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .toggle-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
  }

  .create-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.4rem;
  }
}