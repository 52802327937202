.dashboard-card-container {
  border: 1px solid #3B3E44;
  transition: all 0.3s ease-in-out;

  &.dashboard-action {
    cursor: pointer;
  }

  &.rs-panel {
    border-radius: 2rem;
  }

  &:hover {
    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.3);
    border-color: transparent;
  }
}