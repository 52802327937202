.edit--button-container {
  display: flex;
  justify-content: end;
}

.filter-container {
  margin-top: 1.6rem;
  margin-bottom: 2rem;

  .time-filter {
    margin-right: 2rem;
  }
}


.subscription-detail-container {
  &.rs-panel {
    border-radius: 2rem;
    overflow: hidden;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .subscription-detail-tab {
    &.rs-btn {
      display: flex;
      justify-content: flex-start;
      padding: 2.4rem 2rem;
      border-radius: 0;
    }
  }

  .subscription-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .key {
      font-size: 1.6rem;
      font-weight: 500;
    }

    .value {
      font-size: 3.2rem;
      font-weight: 600;
      margin-top: 1rem;
    }
  }

  .subscription-chart {
    height: calc(100vh - 33.5rem);
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}