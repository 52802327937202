.tournament-card {
  transition: all 0.2s ease-out;
  cursor: pointer;
  margin-bottom: 2rem;
  border-radius: 2rem;
  position: relative;

  &:hover {
    border: 1px solid #6e6af0;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);

    .tournament-title-container {
      .tournament-title {
        color: #6e6af0;
      }
    }
  }

  &.premium {
    .tournament-title-container {
      .tournament-status {
        color: #f48f48;
        border: 1px solid #f48f48;
      }
    }

    &:hover {
      border: 1px solid #f48f48;

      .tournament-title-container {
        .tournament-title {
          color: #f48f48;
        }

        .tournament-status {
          background-color: none;
          background-image: linear-gradient(98deg, #f48f48 -6.85%, #fecf69 87.16%);
          color: white;
        }
      }
    }
  }

  .rs-col {
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    height: 18rem;
    object-fit: cover;
  }

  .tournament-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.7rem 1.6rem 1.9rem 1.6rem;

    .tournament-title {
      font-size: 1.4rem;
      max-width: 20rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @include font-semi-bold;
    }

    .tournament-status {
      padding: 0.4rem 0.8rem;
      font-size: 1rem;
      color: white;
      border: 1px solid rgba(149, 157, 165, 0.2);
      @include font-semi-bold;
    }
  }

  .tournament-premium-tag-wrapper {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.2rem 1.2rem;
    border-radius: 1.2rem;
    background-image: linear-gradient(98deg, #f48f48 -6.85%, #fecf69 87.16%);

    .tournament-premium-tag {
      font-size: 1.4rem;
      color: white;
      font-weight: 600;
    }
  }
}