.challenge-card-wrapper {
  margin-bottom: 3rem;
  cursor: pointer;

  &.rs-panel {
    border-radius: 2rem;
  }

  &:hover {
    box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.3);
  }

  .challenge-card-upper-section {
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    padding: 1.7rem 1.6rem;
    height: 5.8rem;
    background-image: linear-gradient(90deg, #536976 0%, #292e49 100%);

    .chip-wrapper {
      padding: 0.4rem;
      font-size: 1rem;
      color: rgba(255, 255, 255, 1);
      border-radius: 0.4rem;
      background-color: rgba(255, 255, 255, 0.2);
      text-transform: uppercase;
      @include font-bold;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &.status {
        background-color: #4caf50;
      }
    }

    .challenge-icon {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 100%;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      left: 1.2rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .challenge-card-lower-section {
    padding: 1rem 1.2rem;
    min-height: 14.8rem;

    .challenge-description {
      font-size: 1.4rem;
      line-height: 140%;
      margin-top: 1.8rem;
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 5;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
}
