.shorts-main-wrapper {
  width: 100%;

  .shorts-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;

    h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $color-primary-text;
      font-weight: 600;
    }

    .shorts-status {
      width: 12.2rem;
    }

    @media (max-width: 635px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2.4rem;
    }
  }

  .shorts-wrapper {
    width: 100%;
  }
}

.rs-drawer.shorts-drawer {
  width: 100%;
  max-width: 55rem;
}
