.game-store-detail-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h3 {
    margin-bottom: 0;
  }

  .action-btn-container {
    display: flex;
    align-items: center;

    @media (max-width: 480px) {
      margin-top: 1.6rem;
    }

    .action-btn {
      padding: 1.2rem 2.4rem;
      font-size: 1.4rem;
      color: white;
      background-color: rgba(0, 126, 81, 1);

      &:first-child {
        margin-right: 0.8rem;
        background-color: rgba(255, 77, 79, 1);
      }
    }

    .update-btn {
      padding: 1.2rem 2.4rem;
      margin-left: 0.8rem;
    }
  }
}
