.game-form-sub-heading {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 3.2rem;

  &:not(:first-child) {
    margin-top: 3.2rem;
  }
}

.game-media-wrapper {
  display: flex;
  align-items: center;

  .game-icon-field {
    width: 19rem;
    height: 19rem;
    margin-right: 2.4rem;
  }

  .game-video-field {
    width: 30rem;
    height: 19rem;
  }
}

.screenshots-preview-wrapper {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .multi-image-preview {
    width: 20rem;
    height: 20rem;
    position: relative;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .add-screenshot-btn {
    width: 20rem;
    height: 19rem;
    flex: 0 0 auto;
  }
}

.bxo-form-control-50 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.4rem;
  row-gap: 2.4rem;

  @media (max-width: 839px) {
    grid-template-columns: repeat(1, 100%);
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 2.4rem;
  }
}

.game-store-submit-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}