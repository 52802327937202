// Variable
@import "./settings/variables";

// Mixins
@import "./settings/mixins";

// Base
@import "./base/reset";
@import "./base/generic";

// Object
@import "./object/layout";
@import "./object/loader";
@import "./object/notification";

//Pages
@import "./pages/login";
@import "./pages/tournaments";
@import "./pages/games";
@import "./pages/tournament-details";
@import "./pages/moderator";
@import "./pages/spaces";
@import "./pages/access-request";
@import "./pages/space-detail";
@import "./pages/rivalry-zone";
@import "./pages/product";
@import "./pages/redeem-requests";
@import "./pages/wallet";
@import "./pages/payment-history";
@import "./pages/payment-request";
@import "./pages/web-slider";
@import "./pages/xo-shop";
@import "./pages/dashboard";
@import "./pages/shop-product-category";
@import "./pages/add-game";
@import "./pages/game-detail";
@import "./pages/password-reset";
@import "./pages/profile";
@import "./pages/rivalry-zone-details";
@import "./pages/space-post-detail";
@import "./pages/faq";
@import "./pages/shorts";
@import "./pages/manage-pins";
@import "./pages/provisional-tournament-details";
@import "./pages/space-shorts";
@import "./pages/space-shorts-detail";
@import "./pages/coupons";
@import "./pages/add-coupon";
@import "./pages/game-store";
@import "./pages/game-store-detail";
@import "./pages/pro-pass-users-list";
@import "./pages/creators";
@import "./pages/space-membership";
@import "./pages/challenges";
@import "./pages/challenge-detail";

// Components
@import "./components/login";
@import "./components/dropdown-text";
@import "./components/tournament-card";
@import "./components/game-card";
@import "./components/upload-with-preview";
@import "./components/add-game";
@import "./components/game";
@import "./components/tournament-details";
@import "./components/add-space-form";
@import "./components/add-tournament-form";
@import "./components/space-list";
@import "./components/access-mangement";
@import "./components/space";
@import "./components/space-post";
@import "./components/space-post-filters";
@import "./components/pagination";
@import "./components/add-rivalry-zone-form";
@import "./components/product-list";
@import "./components/product-card";
@import "./components/add-product";
@import "./components/create-product";
@import "./components/edit-product";
@import "./components/request-list";
@import "./components/request-header";
@import "./components/slider-card";
@import "./components/withdraw-request-card";
@import "./components/xo-product-header";
@import "./components/product-category-card";
@import "./components/xo-product-list";
@import "./components/xo-product-card";
@import "./components/upload-image";
@import "./components/image-preview";
@import "./components/product-redeem-request";
@import "./components/xo-category-card";
@import "./components/dashboard";
@import "./components/add-product-form";
@import "./components/add-slider-form";
@import "./components/rank-input";
@import "./components/space-card";
@import "./components/create-space-post";
@import "./components/moderator-table";
@import "./components/add-moderator-form";
@import "./components/input-shaded";
@import "./components/sign-up";
@import "./components/user-profile";
@import "./components/table-header.scss";
@import "./components/space-card.scss";
@import "./components/create-space-post.scss";
@import "./components/moderator-table.scss";
@import "./components/add-moderator-form.scss";
@import "./components/space-post-card";
@import "./components/request-feature.scss";
@import "./components/request-sponsor.scss";
@import "./components/file-uploader.scss";
@import "./components/post-detail";
@import "./components/comment-card";
@import "./components/reply-comment-card";
@import "./components/comment-mention";
@import "./components/add-category-modal";
@import "./components/shorts-card";
@import "./components/shorts-player";
@import "./components/tournament-pin-card";
@import "./components/space-post-pin-card";
@import "./components/create-shorts-modal";
@import "./components/shorts-comments";
@import "./components/add-game-to-store-form";
@import "./components/game-store-card";
@import "./components/game-store-details";
@import "./components/reaction-prompt";
@import "./components/pro-pass-users-table";
@import "./components/push-notification-form";
@import "./components/creators-card";
@import "./components/creator-detail-card";
@import "./components/creator-form";
@import "./components/space-membership-form";
@import "./components/space-membership-detail";
@import "./components/challenge-card";
@import "./components/challenge-details";
@import "./components/add-challenge-form";
@import "./components/rich-text-editor";
@import "./components/wallet-card";
@import "./components/admin-wallet-modal";
@import "./components/admin-wallet-info";
@import "./components/wallet-transactions";
@import "./components/create-ai-tournament-modal";
@import "./components/creator-response-card";
@import "./components/brand-enquiry";
@import "./components/creator-assest-link-modal";
@import "./components/create-poll";
@import "./components/space-poll-card";
@import "./components/poll-detail";
@import "./components/poll-vote-card";


// common CSS => will be removed after confirmation
.rs-btn {
  font-size: 1.6rem;
}

input.rs-input {
  font-size: 1.6rem;
}

// To Avoid ResizeObserver loop error
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

::-webkit-scrollbar {
  display: none;
}