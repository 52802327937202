.add-category-modal-container {
  width: 100%;

  .add-category-modal-title {
    font-size: 1.8rem;
    font-weight: 600;
    padding: 1px 0;
  }

  .category-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .upload-container {
      width: 6rem;
      height: 6rem;

      .uploader-preview {
        padding: 0.5rem;

        .close-icon {
          width: 1.5rem;
          height: 1.5rem;

          h6 {
            font-size: 1rem;
          }
        }
      }
    }
  }
}