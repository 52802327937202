.request-feature {
  .rs-modal-body {
    padding-bottom: 0;
  }

  .message-input {
    width: 100%;
    border: 1px solid rgba(98, 103, 107, 0.4);
    border-radius: 4px;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 16px;
    color: #b7b5c6;
    resize: none;
    height: 19rem;
    padding: 1.2rem 1.6rem;
    outline: none;
  }

  .message-error {
    color: red;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }
}
